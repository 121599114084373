<template>
  <v-card>
    <div class="text-center">
      <v-card-title>
        Fluxo: Arquivo de processo
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <strong> Descrição </strong>
            <p>
              Lorem Ipsum é simplesmente um texto fictício da indústria de
              impressão e composição. Lorem Ipsum tem sido o texto fictício
              padrão da indústria desde os anos 1500, quando um impressor
              desconhecido pegou uma galé do tipo e embaralhou para fazer um
              livro de amostra de tipos. Ele sobreviveu não apenas cinco
              séculos, mas também ao salto para a composição eletrônica,
              permanecendo essencialmente inalterado. Ele foi popularizado na
              década de 1960 com o lançamento de folhas de Letraset contendo
              passagens de Lorem Ipsum e, mais recentemente, com software de
              editoração eletrônica como Aldus PageMaker incluindo versões de
              Lorem Ipsum.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <strong> Arquivos </strong>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.acoes="{ item }">
                <v-btn v-show="item" icon :to="{ path: 'fluxos/1' }">
                  <v-icon color="green"> fas fa-download </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <strong> Fluxo </strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                Arquivo entregue no cartorio
              </v-card-title>
              <v-card-text>
                Lorem Ipsum é simplesmente um texto fictício da indústria de
                impressão e composição. Lorem Ipsum tem sido o texto fictício
                padrão da indústria desde os anos 1500, quando um impressor
                desconhecido pegou uma galé do tipo e embaralhou para fazer um
                livro de amostra de tipos. Ele sobreviveu não apenas cinco
                séculos,
              </v-card-text>
              <v-card-actions>
                11/08/2021 10:53 - Movimentado por Marcos Paulo
              </v-card-actions>
            </v-card>
          </v-col>

          <v-icon color="success" large style="margin: 2px auto">
            fas fa-arrow-circle-up
          </v-icon>

          <v-col cols="12">
            <v-card>
              <v-card-title>
                Fluxo criado
              </v-card-title>
              <v-card-text>
                Lorem Ipsum é simplesmente um texto fictício da indústria de
                impressão e composição. Lorem Ipsum tem sido o texto fictício
                padrão da indústria desde os anos 1500, quando um impressor
                desconhecido pegou uma galé do tipo e embaralhou para fazer um
                livro de amostra de tipos. Ele sobreviveu não apenas cinco
                séculos,
              </v-card-text>
              <v-card-actions>
                11/08/2021 10:53 - Movimentado por Alysson
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Nome do Arquivo", value: "nome" },
        { text: "Ações", value: "acoes" },
      ],
      desserts: [
        {
          nome: "Arquivo 1",
        },
        {
          nome: "Arquivo 2",
        },
        {
          nome: "Arquivo ABC",
        },
      ],
    };
  },
};
</script>

<style></style>
